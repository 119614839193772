<template>
<div id="inside">
    <div class="container">
      <div class="row">
        <div class="col-12 text-body px-2 mb-2">
          <button v-if="negociarPagamentoTotal && !negociarPagamentoTotalAlreadyCalled" class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="home()"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
          <button v-if="negociarPagamentoTotal && negociarPagamentoTotalAlreadyCalled" class="btn justify-content-left text-decoration-none btn-link flex-row d-flex align-items-center pr-2 pb-2 pl-1" @click="back()"><i class="fa fa-chevron-left fa-fw pull-left text-info"></i></button>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-body pr-0">
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center" v-if="!negociarPagamentoTotal">Parcelar fatura</h4>
          <h4 class="mb-1 text-primary d-flex flex-row align-items-center" v-if="negociarPagamentoTotal">Negociar valor total</h4>
              <h5 class="mb-1 d-flex flex-row align-items-center" v-if="agreementInfo">{{ agreementInfo.product }}</h5>
          <HeaderOptions/>
          <p class="">Escolha uma das opções propostas:</p>
        </div>
      </div>
    </div>
    <div class="container" v-if="cardOffers && !loadOffers" :style="largura < 500 ? 'padding-left: 25px;' : ''">
      <div class="row">
        <div class="col-lg-3 col-sm-6 col-6" v-for="(value, key, index) in cardOffers.paymentOptions" :key="`${key}-${index}`">
          <div class="card">
            <div class="card-body pb-2 border-top-0 py-2">
              <div class="row">
                <div class="col-lg-12 col-12">
                   <h5 class="text-muted" v-if="value.installmentsNumber === 1">Pagamento à vista</h5>
                  <h5 class="text-muted" v-if="value.installmentsNumber !== 1">Parcelado em {{ value.installmentsNumber }} vezes</h5>
                </div>
              </div>
              <div class="row" v-if="largura > 500">
                <div class="col-lg-12 col-12">
                </div>
              </div>
              <div v-bind:class="[ largura < 500 ? 'row pb-1 mt-1' : 'row pb-4 mt-3' ]">
                <div class="col-xs-8 col-lg-12 col-12 mt-2">
                  <p class="mb-1 small">Condição:</p>
                  <h5 class="text-primary">{{ value.installmentsNumber }}x {{ formatFloatToReal(parseFloat(value.installmentValue, 10)) }}</h5>
                </div>
              </div>
              <div class="row pb-4 mt-2">
                <div class="col-xs-8 col-lg-12 col-12 text-center">
                  <button class="btn mr-1 mb-1 btn-primary" :disabled="loadOffers" @click="resumoAcordo(value)">Confirmar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
      </div>
    </div>
    <div class="container" v-if="loadOffers">
      <ProgressLoading v-bind:loading="loadOffers" />
    </div>
    <div class="container" v-if="!loadOffers">
      <div class="row border-top border-bottom my-3 mx-auto border-info py-4">
        <div class="col-lg-4 my-3">
          <h5 class="mt-3">Selecionar outras opções de negociação:</h5>
        </div>
        <div class="col-lg-4 mt-3">
          <div class="form-group">
            <div class="form-group" v-if="selectOtherOffers && !loadOffers && !negociarPagamentoTotal" >
              <select id="ParcelasSelect" class="form-control mt-2" @change="getValue($event.target.value)">
                <option v-for="(value, key, index) in selectOtherOffers.paymentOptions" :key="`${key}-${index}`" :value="JSON.stringify(value)">{{ value.installmentsNumber }}x {{ formatFloatToReal(parseFloat(value.installmentValue, 10)) }}</option>
                <option v-if="selectOtherOffers.paymentOptions.length < 1">Não existem mais opções disponíveis</option>
              </select>
            </div>
            <div class="form-group" v-if="selectOtherOffers && !loadOffers && negociarPagamentoTotal" >
              <select id="ParcelasSelectPst" class="form-control mt-2" @change="getValue($event.target.value)">
                <option v-for="(value, key, index) in selectOtherOffers.paymentOptions" :key="`${key}-${index}`" :value="JSON.stringify(value)">{{ value.installmentsNumber }}x {{formatFloatToReal(parseFloat(value.installmentValue, 10)) }}</option>
                <option v-if="!newOffersLoaded">Carregar mais opções</option>
                <option v-if="negociarPagamentoTotal && newOffersLoaded && !othersOptionsLoaded">Outras opções de negociação</option>
                <option v-if="selectOtherOffers.paymentOptions.length < 1">Não existem mais opções disponíveis</option>
              </select>
            </div>

          </div>
        </div>
        <div class="my-3 text-center col-lg-2">
          <button class="btn mr-1 mt-2 btn-primary" :disabled="loadOffers || selectOtherOffers.paymentOptions.length < 1" @click="resumoAcordo(null)">Confirmar</button>
        </div>
      </div>
    </div>
    <!-- {{ agreementInfo.allowRenegociation }}
    {{ !compareDates(agreementInfo.dueDate) }}
    {{ !negociarPagamentoTotal }} -->
    <!-- <div class="container" v-if="!loadOffers && !negociarPagamentoTotal && agreementInfo.allowRenegociation && (!compareDates(agreementInfo.dueDate))"> -->
    <div class="container">
  <!-- Conteúdo das outras divs acima -->

    <div class="loading-overlay" v-if="loadOffersPst">
      <ProgressLoading v-bind:loading="loadOffersPst" />
    </div>
  </div>
    <div class="container" v-if="!loadOffers && negociarPagamentoTotal && !negociarPagamentoTotalAlreadyCalled && (!compareDates(agreementInfo.dueDate))">
      <div class="row my-3 mx-auto">
        <div class="col-lg-12 my-3 px-1">
          <button class="btn btn-link mr-1 mt-2 mb-1 btn-lg" @click="negociarPst()">Negociar valor total</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { formatCurrencieValueBRL as formatValue, formatData, truncateString } from "@/utils/helpers";
import ProgressLoading from "../../../modules/auth/components/ProgressLoading.vue";
import HeaderOptions from "../components/HeaderMenu.vue"
import { EventBus } from "@/utils/EventBus";
import { initializeGlobals } from '@/global';
// import Multiselect from "vue-multiselect";


export default {
  name: "propostas",
    components: {
    ProgressLoading,
    HeaderOptions,
    // Multiselect
  },
  data () {
      return {
        form: {
          offerSelected: null,
        },
        loadOffers: false,
        offers: [],
        agreementInfo: [],
        largura: null,
        installment: {},
        loadSetAgreement: false,
        cardOffers: null,
        selectOtherOffers: null,
        negociarPagamentoTotal: false,
        negociarPagamentoTotalAlreadyCalled: false,
        newOffersLoaded: false,
        othersOptionsLoaded: false,
        selectedOption: {},
        paymentOptionsId: [],
        isPossiblePst: false,
        agreementType: null,
        loadOffersPst: false,
        userAdm: {},
        ipUser: null
      }
  },
  methods: {
    formatValue,
    formatData,
    truncateString,

    ...mapActions("meusContratos", ["ActionGetOffersFaturaPortoSeguro", "ActionGetOffersPortoSeguro"]),

    formatFloatToReal(value) {
      return value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
    },

    async negociarPst() {
        localStorage.setItem("currentPageName", this.$options.name+'-negociar-valor-total');
        
        this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
        const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
        const eventAction = 'click:link:negociar-valor-total';

        const eventData = {
          event: 'select_content',
          ev_category: eventCategory,
          ev_action: eventAction,
          ev_label: '',
        };

        window.dataLayer.push({ ...eventData });

      this.negociarPagamentoTotal = false;
      this.agreementType = 'ParcelamentoRecupera';

      if(this.negociarPagamentoTotal) {
        this.negociarPagamentoTotal = false;
      } else {
        this.loadOffers = true;
        this.negociarPagamentoTotal = true;
        var result = [];

        // [24,25,36,40,48] primeira chamada
        // [26,27,28,29,30,31,32] clique no select
        // [33,34,35,37,38,39,40] carregar mais parcelas

        try {
          var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          installmentsOptions: [12,24,25,36,48],
          agreementType: "ParcelamentoRecupera",
          contract: this.agreementInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem('tag'),
          daysDelay: this.agreementInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        result.push(res.data.results);

        const paymentOptionCode = result[0].paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = [12, 24, 25, 36, 48];
        this.paymentOptionsId.push(obj);

         result.forEach((item) => {
          const filteredOptions = item.paymentOptions.filter((option) => {
            const installments = [12, 24, 36, 48];
            return installments.includes(option.installmentsNumber);
          });
          if(this.agreementInfo.contract === item.contract) {
            this.cardOffers = { ...item, paymentOptions: filteredOptions };
          }
        });

       result.forEach((item) => {
          const filteredOptions = item.paymentOptions.filter((option) => {
            const installments = [25];
            return installments.includes(option.installmentsNumber);
          });
          if(this.agreementInfo.contract === item.contract) {
            this.selectOtherOffers = { ...item, paymentOptions: filteredOptions };
          }
        });

        this.selectedOption = this.selectOtherOffers.paymentOptions[0];
        this.loadOffers = false;
        this.negociarPagamentoTotalAlreadyCalled = true;
        

        this.emitUpdatePageViewEvent('dashboard/negociar-valor-total/propostas');

        } catch (error) {
          this.flashMessage.show({
            time:3000,
            status: 'error',
            position:'top right',
            title: 'Erro',
            message: 'Não foi possível buscar informações de negociação total, tente novamente mais tarde!'
          });
          this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
          this.updateCustomData();
          this.eventCallbackNegociarFailureGTM(error, 'Não foi possível buscar informações de negociação total, tente novamente mais tarde!');
          this.$router.push({
            name: "dashboard",
          });
        }
      }
    },

    emitUpdatePageViewEvent(pageName) {
            EventBus.$emit('updatePageView', pageName);
    },

    async newOptions() {
      this.agreementType = 'ParcelamentoRecupera';
      if(this.newOffersLoaded) {
        return;
      }
      this.loadOffers = true;
      const today = new Date()
      today.setDate(today.getDate() + 3);
      var result = [];

      try {

      var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          dueDate: today.toJSON().split('T')[0],
          installmentsOptions: [26,27,28,29,30,31,32],
          agreementType: "ParcelamentoRecupera",
          contract: this.agreementInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem('tag'),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        // if(res.data.code !== 200) {
        //   this.flashMessage.show({
        //     time:3000,
        //     status: 'error',
        //     position:'top right',
        //     title: 'Erro',
        //     message: 'Não foi possível buscar outras opções, tente novamente mais tarde!'
        //   });
        //   this.loadOffers = false;
        //   return;
        // }

        result.push(res.data.results);

        const paymentOptionCode = result.paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = [26,27,28,29,30,31,32,33,34,35,36,37];
        this.paymentOptionsId.push(obj);

       result.forEach((item) => {
          const filteredOptions = item.paymentOptions.filter((option) => {
            const installments = [26,27,28,29,30,31,32,33,34,35,36,37];
            return installments.includes(option.installmentsNumber);
          });

          filteredOptions.map((value) => {
            const installment = this.selectOtherOffers.paymentOptions.filter((option) => {
              return option.installmentsNumber === value.installmentsNumber;
            });

            if(installment.length < 1) {
              this.selectOtherOffers.paymentOptions.push(value);
            }
          });

          if(this.agreementInfo.contract === item.contract) {
            this.selectOtherOffers = { ...item, paymentOptions: this.selectOtherOffers.paymentOptions };
          }
        });
        this.selectedOption = this.selectOtherOffers.paymentOptions[0];

        this.newOffersLoaded = true;
        this.loadOffers = false;
        } catch (error) {
      this.flashMessage.show({
        time:3000,
        status: 'error',
        position:'top right',
        title: 'Erro',
        message: 'Não foi possível buscar ofertas, tente novamente mais tarde!'
      });
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
      this.updateCustomData();
      this.eventCallbackNegociarFailureGTM(error, null);
      this.$router.push({
        name: "dashboard",
      });
    } finally {
      this.loadOffers =  false;
    }
    },

    async loadOthersOptions() {
      this.agreementType = 'ParcelamentoRecupera';
      this.othersOptionsLoaded = false;

      this.loadOffers = true;
      const today = new Date()
      today.setDate(today.getDate() + 3);
      var result = [];

      try {
      

      var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          dueDate: today.toJSON().split('T')[0],
          installmentsOptions: [33,34,35,37,38,39,40],
          agreementType: "ParcelamentoRecupera",
          contract: this.agreementInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem('tag'),
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        result.push(res.data.results);

        const paymentOptionCode = result.paymentOptionCode;
        let obj = {};
        obj[paymentOptionCode] = [33,34,35,37,38,39,40];
        this.paymentOptionsId.push(obj);

       result.forEach((item) => {
          const filteredOptions = item.paymentOptions.filter((option) => {
            const installments = [33,34,35,37,38,39,40];
            return installments.includes(option.installmentsNumber);
          });

          filteredOptions.map((value) => {
            const installment = this.selectOtherOffers.paymentOptions.filter((option) => {
              return option.installmentsNumber === value.installmentsNumber;
            });

            if(installment.length < 1) {
              this.selectOtherOffers.paymentOptions.push(value);
            }
          });
          if(this.agreementInfo.contract === item.contract) {
            this.selectOtherOffers = { ...item, paymentOptions: this.selectOtherOffers.paymentOptions };
          }
        });

        this.selectedOption = this.selectOtherOffers.paymentOptions[0];

        this.othersOptionsLoaded = true;
        this.loadOffers = false;
      } catch (error) {
      this.flashMessage.show({
        time:3000,
        status: 'error',
        position:'top right',
        title: 'Erro',
        message: 'Não foi possível buscar ofertas, tente novamente mais tarde!'
      });
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
      this.updateCustomData();
      this.eventCallbackNegociarFailureGTM(error, null);
      this.$router.push({
        name: "dashboard",
      });
    } finally {
      this.loadOffers =  false;
    }
    },

    async back() {
      this.eventClickArrowToBackGTM();
      this.negociarPagamentoTotal = false;
      await this.loadParcelamentoFatura();
      await this.verifyIfHasPst();
    },

    isDatePassedMoreThan30Days(dateString) {
      const date = new Date(dateString);
      const currentDate = new Date();

      const differenceInTime = currentDate.getTime() - date.getTime();
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);

      return differenceInDays > 30;
    },

    async loadParcelamentoFatura() {
      this.loadOffers = true;
      this.agreementInfo = this.$route.params.agreementInfo;
      this.agreementType = 'ParcelamentoFatura';
      // this.isPossiblePst = this.isDatePassedMoreThan30Days(this.agreementInfo.dueDate);

    try {
      var result = await this.ActionGetOffersFaturaPortoSeguro({
        clientCode: this.agreementInfo.clientCode,
        credor: this.agreementInfo.credor,
        agreementType: "ParcelamentoFatura",
        contract: this.agreementInfo.contract,
        tag: localStorage.getItem('tag'),
        daysDelay: this.agreementInfo.daysDelay,
        UserID: this.userAdm ? this.userAdm.UserID : null,
        UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
        OS:navigator.platform,
        IP: this.ipUser ? this.ipUser : '',
      });

      const filteredOptions = result.paymentOptions.filter((option) => {
        const installments = [1, 3, 6, 12, 24];
        return installments.includes(option.installmentsNumber);
      });
        this.cardOffers =  { ...result, paymentOptions: filteredOptions };

       const filteredOptionsSelected = result.paymentOptions.filter((option) => {
        const installments = [1, 3, 6, 12, 24];
        return !installments.includes(option.installmentsNumber);
      });
        this.selectOtherOffers =  { ...result, paymentOptions: filteredOptionsSelected };

    this.selectedOption = this.selectOtherOffers.paymentOptions[0];


    } catch (error) {
      this.flashMessage.show({
        time:3000,
        status: 'error',
        position:'top right',
        title: 'Erro',
        message: 'Não foi possível buscar ofertas, tente novamente mais tarde!'
      });
      this.eventCallbackNegociarFailureGTM(error, null);
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
      this.updateCustomData();
      this.$router.push({
        name: "dashboard",
      });
    } finally {
      this.loadOffers =  false;
    }
    },

    getValue(selectedValue) {
      if(selectedValue === "Carregar mais opções") {
        this.newOptions();
      }
      if(selectedValue === "Outras opções de negociação") {
        this.loadOthersOptions();
      } else {
        const value = JSON.parse(selectedValue);
        this.eventSelecionarOutrasOpcoesNegociacaoGTM(value);
        return this.selectedOption = value;
      }
    },

    async resumoAcordo(offer) {

      if(offer === null) {
          offer = this.selectedOption;
      }

      this.eventClickConfirmarGTM(offer);
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard/resumoAcordo', 'resumo-acordo');
      this.updateCustomData();
      this.$router.push({
        name: "resumoAcordo",
        params: {
          offerInfo: {
            clientCode: this.agreementInfo.clientCode,
            credor: this.agreementInfo.credor,
            product: this.agreementInfo.product,
            dueDate: this.cardOffers.dueDate,
            dueDateOriginal: this.agreementInfo.dueDate,
            contract: this.agreementInfo.contract,
            paymentOptionCode: this.cardOffers.paymentOptionCode,
            paymentOptionsCodeIds: this.paymentOptionsId,
            allowRenegociation: this.agreementInfo.allowRenegociation,
            invoiceOption: offer.invoiceOption,
            agreementType: this.agreementType,
            ...offer
          },
          fromProposals: true,
          isPst: this.negociarPagamentoTotal && this.negociarPagamentoTotalAlreadyCalled,
          from: 'propostas',
          agreementInfo: this.agreementInfo
        }
      });
    },

    compareDates (date) {
      let parts = date.split('-')
      let today = new Date()

      date = new Date(parts[0], parts[1] - 1, parts[1])

      return date >= today ? true : false;

    },

    home() {
      this.eventClickArrowToBackGTM();
      this.buildAndSetUserData(this.userDataStorage.cpfCnpj, this.userDataStorage.id, this.protocol+'//'+ this.host+ '/dashboard', 'Dashboard');
      this.updateCustomData();
      this.$router.push({
        name: "dashboard",
      });
    },

    async verifyIfHasPst() {
      this.loadOffersPst = true;
      this.negociarPagamentoTotalAlreadyCalled = false;

      try {
          var result = [];
          var res = await this.ActionGetOffersPortoSeguro({
          clientCode: this.agreementInfo.clientCode,
          credor: this.agreementInfo.credor,
          installmentsOptions: [24],
          agreementType: "ParcelamentoRecupera",
          contract: this.agreementInfo.contract,
          discount: 0,
          inputValue: 0,
          tag: localStorage.getItem('tag'),
          daysDelay: this.agreementInfo.daysDelay,
          UserID: this.userAdm ? this.userAdm.UserID : null,
          UserFullName: this.userAdm ? this.userAdm.UserFullName : null,
          OS:navigator.platform,
          IP: this.ipUser ? this.ipUser : '',
        });

        result.push(res.data.results);
        this.negociarPagamentoTotal = true;
        this.loadOffersPst = false;
        } catch (error) {
          this.negociarPagamentoTotal = false;
          this.loadOffersPst = false;
          return false;
        }
    },

    buildAndSetUserData(doc, id, pageLocation, pageName) {
      // const pageName = this.$options.name;
      const userData = {
                      cpfCnpj: doc, // Substitua pelo CPF/CNPJ real
                      id: id, // Substitua pelo ID real
                      page_location: pageLocation,
                      pageName: pageName
                    };
                    localStorage.setItem("userData", JSON.stringify(userData));
    },

    eventClickArrowToBackGTM() {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'click:button:voltar';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: '',
          };

          console.log("eventData ClickArrowToBack PROPOSTAS: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },


    eventClickConfirmarGTM(offer) {
          var typeConfirm = this.negociarPagamentoTotal && this.negociarPagamentoTotalAlreadyCalled ? 'confirmar' : 'confirmar';
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = `click:button:${typeConfirm}`;

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: this.buildPayloadOfferChoose(offer),
            product_identify: this.agreementInfo.contract,
            product_category: this.agreementInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.agreementInfo.product,
            vertical: 'bank',
          };

          console.log("eventData ClickConfirmar PROPOSTAS: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },

    buildPayloadOfferChoose(offer) {
      var formatedPayload = null;
      if(offer.installmentsNumber === 1) {
        formatedPayload = 'pagamento-a-vista';
      } else {
        formatedPayload = `parcelado-em-${offer.installmentsNumber}-vezes`;
      }
      return formatedPayload;
    },

    buildPayloadOfferChooseOtherOptionsOfNegociation(offer) {
      var formatedPayload = null;
      formatedPayload = `${offer.installmentsNumber}x ${this.formatFloatToReal(parseFloat(offer.installmentValue, 10))}`;
      
      return truncateString(formatedPayload, 100);
    },

    eventSelecionarOutrasOpcoesNegociacaoGTM(offer) {
          this.pageName = localStorage.getItem('currentPageName') === null ? 'dashboard' : localStorage.getItem('currentPageName');
          const eventCategory = `portal-de-negociacao:porto:${this.pageName}`;
          const eventAction = 'selecionou:option:outra-opcao-de-negociacao';

          const eventData = {
            event: 'select_content',
            ev_category: eventCategory,
            ev_action: eventAction,
            ev_label: this.buildPayloadOfferChooseOtherOptionsOfNegociation(offer),
            product_identify: this.agreementInfo.contract,
            product_category: this.agreementInfo.product,
            product_user_profile: 'titular',
            brand: 'porto',
            product: this.agreementInfo.product,
            vertical: 'bank',
          };

          console.log("eventData SelecionarOutrasOpcoesNegociacao PROPOSTAS: " + JSON.stringify(eventData))

          window.dataLayer.push({ event: 'select_content', ...eventData });

        },
    
    eventCallbackNegociarFailureGTM(error, msg) {
                    console.log('errorrrrr: ' + JSON.stringify(error.status));
                    const pageName = this.$options.name;
                    const eventCategory = `portal-de-negociacao:porto:${pageName}`;
                    const eventAction = 'solicitar-opcoes-pagamento:alert';
                    const eventLabel = truncateString(msg !== null ? msg : 'Não foi possível buscar ofertas, tente novamente mais tarde!', 100);
                    const eventCode = error.status;
                    const eventErrorService = '';
                    const eventAlertService = truncateString(error.data.message, 100);
                    const eventAlert = 'select_content';
                    const eventAlertType = 'erro';
                    const eventData = {
                      ev_category: eventCategory,
                      ev_action: eventAction,
                      ev_label: eventLabel,
                      alert_code: eventCode,
                      error_service: eventErrorService,
                      alert_service_message: eventAlertService,
                      alert_event: eventAlert,
                      alert_type: eventAlertType,
                    };

                    console.log("eventData CLICK NEGOCIAR ERRO: " + JSON.stringify(eventData))

                    window.dataLayer.push({ event: 'alert', ...eventData });
    },

    updateCustomData() {
      const globals = initializeGlobals();
      window.customData = globals.customData;
    }

  },
  
  async created() {
    this.updateCustomData();
    this.ipUser = localStorage.getItem('ipUser');
    localStorage.setItem("currentPageName", this.$options.name);
    this.userDataStorage = JSON.parse(localStorage.getItem("userData"));
    this.userAdm = JSON.parse(localStorage.getItem("userAdm"));
    this.protocol = window.location.protocol;
    this.host = window.location.host;
    this.path = window.location.pathname;
    this.pageLocation = this.protocol + '//' + this.host + this.path;
    this.negociarPagamentoTotal = false;
    this.negociarPagamentoTotalAlreadyCalled = false;
    await this.loadParcelamentoFatura();
    this.largura = window.screen.width;
    await this.verifyIfHasPst();

  },
  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  }
};
</script>

<style scoped>
@media(max-width: 500px) {
    .card {
        margin-right: 0;
    }
}

.loading-overlay {
  position: absolute;
  top: 200px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgba(255, 255, 255, 0.8); */
  z-index: 999; /* Ajuste para que o elemento de carregamento esteja na parte superior */
}
</style>
